<template>
  <div>
    <ui-header :heading="$tc('models.user', 2)">
      <ui-link class="add_new_button" type="text" :route="{ name: 'companies-users-create' }">{{
        `${$tc('actions.add_new', 1)} ${$tc('models.user')}`
      }}</ui-link>
    </ui-header>

    <company-user-list
      :users="users"
      v-on:removeUser="removeUser"
      v-loading="loading"
    >
    </company-user-list>
  </div>
</template>

<script>
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';
import CompanyUserList from '../components/CompanyUserList';
import { getCompanyUsers, removeCompanyUser } from '../api';

export default {
  data() {
    return {
      users: [],
      loading: false
    };
  },

  components: {
    UiHeader,
    UiLink,
    CompanyUserList
  },

  methods: {
    async getUsers() {
      this.loading = true;
      try {
        const users = await getCompanyUsers(
          this.$router.history.current.params.id
        );
        if (users.length > 0) {
          this.users = users.sort((a, b) => (a.lastName > b.lastName ? 1 : -1));
        }
        this.loading = false;
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.load', { msg: this.$tc('user', 2) }),
          type: 'error'
        };
        this.$message(msg);
      }
    },

    async removeUser(userId) {
      this.loading = true;
      try {
        const success = await removeCompanyUser(
          this.$router.history.current.params.id,
          userId
        );
        if (success) {
          this.users = this.users.filter(u => u.id !== userId);
        }
        this.loading = false;
        const msg = {
          message: this.$t('feedback.delete_success'),
          type: 'success'
        };
        this.$message(msg);
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t('errors.failed', this.$t('actions.delete')),
          type: 'error'
        };
        this.$message(msg);
      }
    }
  },

  created() {
    this.getUsers();
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.companies", link: "/app/companies/"},
      {name: "models.users", link: ""}
    ]);
  }
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>
